.selected-card {
  background-color: blue;
  color: white;
}

.btn-primary {
    /* Background color */
    background-color: #0026fe; /* Replace with your desired primary color */
    
    /* Text color */
    color: #ffffff; /* White text color */
    
    /* Border */
    border: 1px solid rgb(15, 15, 243); /* Border color same as background color */
    border-radius: 5px; /* Rounded corners */
    
    cursor: pointer;
}

.slideshow-height {
  min-height: 300px !important;
}

.active {
  background-color: black;
}

/* Custom styles for alert dialogs */
.custom-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

/* Other custom styles as needed */